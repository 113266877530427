import { IS_DEV } from "@constants";
import { memoize } from "lodash";
import { IntlErrorCode } from "@formatjs/intl";
import { OnErrorFn, OnWarnFn } from "@formatjs/intl/src/types";
import { Localized } from "@frontend/configuration";

import { readFromLocalStorage, writeToLocalStorage } from "@services/data";
import { reportError } from "@services/logger";
import { fnv1a, isFunction } from "@services/utils";
import { di } from "@di";
import {
  COMPILED_LOCALES_PATH,
  DATE_LOCALE_LOADERS,
  DEFAULT_LOCALE,
  DEFAULT_LOCALES,
  SAVED_LANG_CODE_LOCAL_STORAGE_KEY,
} from "./constants";
import { Locale, LocaleData, Locales } from "./types";

export const getAvailableLocales = (): Locales => {
  if (di.hasRegistration("locales")) {
    return di.resolve("locales");
  }

  reportError(`[getAvailableLocales] Dependency "locales" is not registered.`);
  return DEFAULT_LOCALES;
};

export const isLocaleSupported = (
  locale: unknown,
  availableLocales: Locales = getAvailableLocales()
): locale is Locale => {
  const locales = new Set(availableLocales);

  return locales.has(<Locale>locale);
};

export const getUserLocale = (fallback?: Locale): Locale => {
  const { value: savedLocale, error } = readFromLocalStorage<string | null>(
    SAVED_LANG_CODE_LOCAL_STORAGE_KEY,
    null
  );

  if (error) {
    reportError(
      "[getUserLanguageCode] Unable to get saved user locale!\n",
      error
    );
  } else if (isLocaleSupported(savedLocale)) {
    return savedLocale;
  }

  reportError(
    `[getUserLanguageCode] Saved locale "${savedLocale}" is not supported yet or invalid. Falling back to "${fallback}"...`
  );

  if (isLocaleSupported(fallback)) {
    return fallback;
  }

  const browserLanguageCode = navigator.language || navigator.languages.at(0);

  reportError(
    `[getUserLanguageCode] Given fallback value "${String(
      fallback
    )}" is not supported yet or invalid. Trying to use browser language "${browserLanguageCode}"...`
  );

  if (isLocaleSupported(browserLanguageCode)) {
    return browserLanguageCode;
  }

  reportError(
    `[getUserLanguageCode] Browser locale "${browserLanguageCode}" is not supported yet or invalid. Falling back to defaults...`
  );

  return getAvailableLocales()[0] || DEFAULT_LOCALE;
};

export const saveUserLanguageCode = (locale: Locale) => {
  const { error } = writeToLocalStorage<Locale>(
    SAVED_LANG_CODE_LOCAL_STORAGE_KEY,
    locale
  );

  if (error) {
    reportError("[saveUserLanguageCode] Unable to save user locale!\n", error);

    throw error;
  }
};

export const loadLocaleData = async (locale: Locale): Promise<LocaleData> => {
  const { default: messages } = await import(
    `${COMPILED_LOCALES_PATH}${locale}.json`
  );

  return messages;
};

export const handleIntlError: OnErrorFn = (error) => {
  if (error.code === IntlErrorCode.MISSING_TRANSLATION) {
    if (IS_DEV) {
      // console.debug(`${error.message}\n${JSON.stringify(error.descriptor)}`);
    }

    return;
  }

  reportError(`[handleIntlError] ${error.message}`, error);
};

export const handleIntlWarning: OnWarnFn = (message) => {
  reportError(`[handleIntlWarning] ${message}`);
};

/**
 * @internal Do not use outside
 */
const getCurrentLocale = (fallback: Locale): Locale => {
  if (di.hasRegistration("locale")) {
    return di.resolve("locale");
  }

  return fallback;
};

/**
 * @internal Do not use outside
 */
const isLocalizedShape = <T = Localized<Locales>>(data: unknown): data is T => {
  return Boolean(data && typeof data === "object");
};

/**
 * @internal Do not use outside
 */
const extractor = <T, F = T>(
  data?: Partial<Localized<Locales, T>> | null | undefined,
  fallback?: F
): T | F => {
  const locale = getCurrentLocale(DEFAULT_LOCALE);

  try {
    if (!isLocaleSupported(locale)) {
      throw new Error(
        `Locale "${String(locale)}" is missing or not supported.`
      );
    }

    if (!isLocalizedShape(data)) {
      throw new TypeError(
        `Localized data is invalid. Expected type of Object with locale properties, got ${JSON.stringify(
          data
        )} instead. Provided fallback: ${JSON.stringify(fallback)}.`
      );
    }

    if (!(locale in data)) {
      throw new Error(
        `Locale "${locale}" is missing in given localized data:\n${JSON.stringify(
          data,
          null,
          2
        )}.`
      );
    }

    const extracted = data[locale];

    if (!extracted) {
      throw new Error(
        `Extracted value is "undefined". Arguments:\n${JSON.stringify(
          { locale, fallback, data },
          null,
          2
        )}.`
      );
    }

    return extracted;
  } catch (error) {
    if (error instanceof Error) {
      reportError("[extractLocalized]", error);
    } else {
      reportError("[extractLocalized] An unexpected error occurred!");
    }

    if (data && typeof data === "object" && DEFAULT_LOCALE in data) {
      return data?.[DEFAULT_LOCALE] as T;
    }

    return fallback as F;
  }
};

/**
 * Extracts localized data from the passed object;
 *
 * Attention!
 * This is a temporary solution to handle localized strings from the `Config`;
 * We should rewrite configs to the pre-compiled (with `AST`) messages,
 * build with the code-splitting and cache management,
 * and handle them via `react-intl` as regular messages.
 *
 * Note: It uses `DEFAULT_LANG_CODE` and `di.resolve("locale")` as an external dependencies.
 */
export const extractLocalized = memoize(
  extractor,
  (localizedData, fallback) => {
    const locale = getCurrentLocale(DEFAULT_LOCALE);
    const fallbackKey = fallback ? fnv1a(JSON.stringify(fallback)) : "-";
    const localizedDataKey = localizedData
      ? fnv1a(JSON.stringify(localizedData))
      : "-";

    return [localizedDataKey, fallbackKey, String(locale)].join("|");
  }
);

export const loadDateLocale = async (
  locale: Locale,
  localeLoaders = DATE_LOCALE_LOADERS
) => {
  let localeLoader = localeLoaders[locale];

  if (!isFunction(localeLoader)) {
    reportError(
      `[loadDateLocale] Invalid localeLoader. Either locale key "${locale}" doesn't exist in the localeLoaders or invalid.`
    );

    localeLoader = localeLoaders[DEFAULT_LOCALE];
  }

  const { default: dateLocaleData } = await localeLoader();

  return dateLocaleData;
};
