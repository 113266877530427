import { useIntlOutsideProvider } from "@hooks/useIntlOutsideProvider";
import React, { ComponentProps, HTMLProps, ReactNode } from "react";
import { cn } from "@services/react";
import {
  Icons24Alert,
  Icons24LoopLeftLine,
  NavItemButton,
  Spinner,
} from "@frontend/assaia-ui";
import { reportMessage } from "@services/logger";

import s from "./style.module.scss";

type ConditionalProps<T> =
  | ({
      withRefreshButton?: true;
      refreshButtonProps?: ComponentProps<typeof NavItemButton> & {
        withoutRedirect?: boolean;
        redirectUrl?: string;
      };
    } & T)
  | ({
      withRefreshButton?: false;
      refreshButtonProps?: never;
    } & T);

export type ErrorMessageProps = ConditionalProps<
  { message?: ReactNode } & HTMLProps<HTMLDivElement>
>;

export const ErrorMessage = ({
  message,
  className,
  children,
  withRefreshButton,
  refreshButtonProps: {
    withoutRedirect,
    redirectUrl,
    ...refreshButtonProps
  } = {},
  ...restProps
}: ErrorMessageProps) => {
  const intl = useIntlOutsideProvider();

  let defaultTitle: ReactNode = <Spinner />;

  if (intl) {
    defaultTitle = intl.formatMessage(
      {
        defaultMessage:
          "Assaia ApronAI is unavailable. {br} Please try later...",
        description: "Error Message default title",
      },
      { br: <br /> }
    );
  }

  const refreshButton = withRefreshButton ? (
    <NavItemButton
      label={
        intl?.formatMessage({
          defaultMessage: "Refresh",
          description: "Error Message default refresh button label",
        }) || "Retry"
      }
      icon={<Icons24LoopLeftLine />}
      {...refreshButtonProps}
      className={cn(s.refreshBtn, refreshButtonProps.className)}
      onClick={(event) => {
        reportMessage("Reload page from error state");
        refreshButtonProps.onClick?.(event);

        if (!withoutRedirect) {
          window.history.pushState({}, document.title, redirectUrl || "/");
        }
        window.location.reload();
      }}
    />
  ) : null;

  return (
    <div className={cn(s.errorMessage, className)} {...restProps}>
      <Icons24Alert className={s.towerIcon} />
      <h1 className={s.title}>{message || defaultTitle}</h1>
      {refreshButton}
      {children}
    </div>
  );
};
