import { useEffect, useMemo, useState } from "react";
import { createIntl, createIntlCache } from "react-intl";
import {
  getUserLocale,
  handleIntlError,
  handleIntlWarning,
  Locale,
  loadLocaleData,
} from "@i18n";

export const useIntlOutsideProvider = () => {
  const [isIntlReady, setIsIntlReady] = useState(false);

  const intl = useMemo(() => {
    return createIntl(
      {
        locale: getUserLocale(),
        messages: {},
        onError: handleIntlError,
        onWarn: handleIntlWarning,
      },
      createIntlCache()
    );
  }, []);

  useEffect(() => {
    loadLocaleData(intl.locale as Locale)
      .then((messages) => {
        intl.messages = messages;
        setIsIntlReady(true);
      })
      .catch(console.warn);
  }, [intl]);

  if (!isIntlReady) {
    return null;
  }

  return intl;
};
