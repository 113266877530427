import clamp from "lodash/clamp";
import round from "lodash/round";

export const isFiniteNumber = <T>(
  value: T
): value is T extends number ? T : never => {
  return typeof value === "number" && Number.isFinite(value);
};

export const isFunction = <T>(
  value: T
): value is T extends Function ? T : never => {
  return typeof value === "function";
};

export const toSafeCssNumber = (
  value: number,
  precision = 4,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER
) => {
  if (typeof value !== "number") {
    return 0;
  }

  const result = round(clamp(value, min, max), precision);

  return isNaN(result) || !isFiniteNumber(result) ? 0 : result;
};

// Creates an FNV hash key based on string value
export const fnv1a = (value: string) => {
  if (typeof value !== "string") {
    throw new TypeError("Input value must be a string.");
  }

  const FNV_prime = 0x01000193; // 16777619
  let hash = 0x811c9dc5; // 2166136261 (FNV offset basis)

  for (let i = 0; i < value.length; i++) {
    hash ^= value.charCodeAt(i);
    hash *= FNV_prime;
    hash >>>= 0; // Обеспечивает, что hash остается 32-битным беззнаковым целым
  }

  let hashString = hash.toString(16);

  if (hashString.length < 8) {
    hashString = "0".repeat(8 - hashString.length) + hashString;
  }

  return hashString;
};

export function randomId(): string {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0] || 0;
  return uint32.toString(16);
}

export const predicateNonNullable = <I>(item: I): item is NonNullable<I> => {
  return Boolean(item);
};
